
import Vue from 'vue';
import NoChartData from '../no-data/NoChartData.vue';
import utils from '../../../../util';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import CompanyLogos from '../../../../assets/networkLogos/slingData';
import Tooltips from '../tooltip/chartTooltip.vue';

interface SlingLogos {
  name: string;
  logo: string;
}

export default Vue.extend({
  inheritAttrs: false,
  name: 'slingNetworkLogosList',
  components: { NoChartData, EditModuleBtn, Tooltips },
  props: [
    'sectionConfig',
    'componentConfig',
    'title',
    'chartType',
    'dataSource',
    'isExporting',
    'exportData',
    'componentHeight',
  ],
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
    CompanyLogos: SlingLogos[];
  } => ({
    chartInstance: undefined,
    CompanyLogos: CompanyLogos,
  }),
  watch: {
    breakpoints: {
      handler(): void {
        this.onWindowResize();
      },
    },
  },
  mounted() {
    window.addEventListener('optimizedResize', this.onWindowResize);
    setTimeout(() => {
      if (this.validFeedSource && this.hasEnoughData) {
        this.$emit('rendered', { empty: false });
      } else {
        this.$emit('rendered', { empty: true });
      }
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
  computed: {
    getCompanyLogos(): Array<unknown> {
      return this.isHalfWidth ? this.CompanyLogos.slice(0, 36) : this.CompanyLogos;
    },
    isHalfWidth(): boolean {
      return this.breakpoints.includes('lg6');
    },
    showNoDataChart(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      } else if (this.componentConfig.hideIfNoData && !this.hasEnoughData) {
        return false;
      }
      return true;
    },
    loading(): boolean {
      return utils.isWaitingOnData(this);
    },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      }
      return this.chartData[0];
    },
    limitChartsEnoughData(): boolean {
      const data = utils.adDataForKey(this, this.dataSource);
      if (!Array.isArray(data)) {
        return false;
      }
      return data.length === 1;
    },
    chartClass(): string {
      let css = '';
      if (this.componentConfig.class) {
        css = css + ' ' + this.componentConfig.class;
      }
      if (this.isExporting) {
        css = css + ' exporting';
      }
      return css;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartData(): any {
      const rows = utils.adDataForKey(this, this.dataSource);
      if (!rows || !Array.isArray(rows) || rows.length === 0) {
        return [];
      }
      return rows;
    },
    breakpoints(): boolean {
      return this.componentConfig.breakpoints;
    },
    validFeedSource(): boolean {
      if (this.componentConfig?.feedSource.includes(this.chartData[0]?.FeedSource)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWindowResize(): any {
      setTimeout(() => {
        if (this.chartInstance) {
          // console.log('chartInstance line resize', this.chartInstance.id);
          this.chartInstance.resize();
        }
      }, 250);
    },
  },
});
