
import Vue from 'vue';
import summaryMap from '../components/maps/summary-map.vue';
import SummaryMetricsController from '../controllers/summaryMetricsController.vue';
import SummaryTacticTablesController from '../controllers/summaryTacticTablesController.vue';
import { getTopTacticData, getTopTactics, renderComponent } from '../utils';
import { CurrentSection } from '../../../../types/layout';
import { TopTacticsGroup, TopTacticsWithMetrics } from '../types';
import util from '../../../../util';

export default Vue.extend({
  name: 'cardsLeftMapRight',
  inheritAttrs: false,
  props: [
    'sectionConfig',
    'componentConfig',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'config',
    'mapRenderCacheKey',
    'mapCacheKey',
    'hasRequiredData',
    'mapTilerMapId',
    'hasMapData',
  ],
  // data: (): {} => ({}),
  mounted(): void {
    this.setMapHeight();

    // need timeout to allow summary data to be set when advertiser change
    setTimeout(() => {
      this.setTopTacticData();
    }, 100);
  },
  components: { SummaryTacticTablesController, summaryMap, SummaryMetricsController },
  computed: {
    mapHeight(): number {
      return this.$store.state?.summary?.dimensions?.mapHeight || 0;
    },
    topTacticData(): object {
      return this.$store.state?.summary?.topTactics;
    },
    tacticColumnCount(): number {
      if (!this.hasMapData) {
        return 12;
      }
      return this.twoCardCols ? 6 : 3;
    },
    summaryHeight(): number {
      return this.$store.state?.summary?.dimensions?.summaryHeight || 0;
    },
    twoCardCols(): boolean {
      return this.topTacticData?.length > 5;
    },
    summaryData(): object {
      return this.isExporting ? this.exportData?.summaryData : this.$store.state.customer?.summaryPerformance;
    },
  },
  methods: {
    getMapHeight(): number {
      // hard coding for now because I think it would be this height in all cases? Unless we want to dynamically adjust
      return 680;
    },
    setMapHeight(): void {
      this.$store.dispatch('summary/setMapHeight', this.getMapHeight());
    },
    setTopTacticData(): void {
      const tactics = getTopTactics().reduce((all: Array<TopTacticsWithMetrics>, tactic: TopTacticsGroup) => {
        const data = getTopTacticData(this.summaryData, tactic);
        if (data) {
          all.push(data);
        }
        return all;
      }, []);
      this.$store.dispatch('summary/setTopTactics', tactics);
    },
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
    renderComponent(cpnt: string): boolean {
      return renderComponent(cpnt, this.componentConfig.subComponent, this.isExporting);
    },
    rendered(config: object): void {
      this.$emit('rendered', config);
    },
  },
  watch: {
    summaryData: {
      handler(): void {
        this.setTopTacticData();
        this.setMapHeight();
      },
    },
    summaryHeight: {
      handler(oldHeight, newHeight): void {
        if (oldHeight !== newHeight) {
          this.setMapHeight();
        }
      },
    },
  },
});
