
import Vue from 'vue';
import NoChartData from '../no-data/NoChartData.vue';
import utils from '../../../../util';
import TableUi from './siteImpactTableUi.vue';
import Tooltips from '../tooltip/chartTooltip.vue';
import EditModuleBtn from '../buttons/editModuleBtn.vue';

export default Vue.extend({
  inheritAttrs: false,
  name: 'siteImpactTable',
  components: { NoChartData, TableUi, Tooltips, EditModuleBtn },
  props: [
    'sectionConfig',
    'componentConfig',
    'title',
    'topOptions',
    'headers',
    'dataSource',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'componentHeight',
  ],
  data: (): {
    childHasRendered: boolean;
    renderKey: Array<number>;
  } => ({
    childHasRendered: false,
    renderKey: [5],
  }),
  mounted() {
    let interval;
    if (this.isXLS) {
      return;
    }
    this.$nextTick(() => {
      interval = setInterval(() => {
        // wait until child has rendered
        if (this.childHasRendered) {
          clearInterval(interval);
          if (this.tableData.rows.length === 0) {
            this.$emit('rendered', { empty: true });
          } else {
            this.$emit('rendered', { empty: false });
          }
        }
      }, 100);
    });
  },
  computed: {
    loading(): boolean {
      return utils.isWaitingOnData(this);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableHeaders(): any[] {
      return this.componentConfig.headers;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableData(): any {
      let rows = utils.adDataForKey(this, this.dataSource);
      if (!rows || !Array.isArray(rows)) {
        this.$emit('rendered', { empty: true });
        return [];
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rows = rows.map((x: any) => ({ ...x })); // shallow clone, for transforms to stay localized to this copy of the data
      if (this.componentConfig.columns) {
        const columns = this.componentConfig.columns;

        // remove columns that aren't in the columns
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows = rows.map((x: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const c: any = {};
          columns.forEach((col: string) => {
            c[col] = x[col];
            // console.log(`col is ${col}, and c[col] is ${c[col]}`);
          });
          c.Index = x.Index;
          return c;
        });
      }

      let truncated = false;
      // if (this.isExporting && rows.length > 20) {
      if (!this.isXLS && rows.length > 20) {
        truncated = true;
        // don't filter out rows here, let them get sorted in the table component
        // rows = rows.slice(0, 20);
      }

      return { rows, truncated };
    },
    currentCampaign(): object | null {
      if (this.isExporting) {
        if (this.exportData.campaign) {
          return this.exportData.campaign;
        }
        if (Array.isArray(this.exportData.adData.CampaignList) && this.exportData.adData.CampaignList.length > 0) {
          return this.exportData.adData.CampaignList[0];
        } else if (
          Array.isArray(this.exportData.advertiser.CampaignList) &&
          this.exportData.advertiser.CampaignList.length > 0
        ) {
          return this.exportData.advertiser.CampaignList[0];
        }
      }
      if (
        Array.isArray(this.$store.state.customer?.campaignAdPerformance?.CampaignList) &&
        this.$store.state.customer.campaignAdPerformance.CampaignList.length === 1
      ) {
        return this.$store.state.customer.campaignAdPerformance.CampaignList[0];
      }
      return null;
    },
    siteImpactCreative(): string | null {
      if (this.multipleCampsSelected) {
        return null;
      }
      if (typeof this.currentCampaign?.CreativeImage === 'string') {
        const low = this.currentCampaign.CreativeImage.toLowerCase();
        let needle = 'http://cdn.compulsemailstats.com';
        if (low.substr(0, needle.length) === needle) {
          return '/compulsemailstats' + this.currentCampaign.CreativeImage.substr(needle.length);
        }
        needle = 'https://cdn.compulsemailstats.com';
        if (low.substr(0, needle.length) === needle) {
          return '/compulsemailstats' + this.currentCampaign.CreativeImage.substr(needle.length);
        }
        return this.currentCampaign.CreativeImage;
      }
      // eslint-disable-next-line no-console
      console.log('missing emailsi creative', this.exportData?.campaign);
      return null;
    },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) return true;
      return this.tableData?.rows?.length > 0 || this.componentConfig.filter;
    },
    showNoDataChart(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      } else if (this.componentConfig.hideIfNoData && !this.hasEnoughData) {
        return false;
      }
      return true;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    multipleCampsSelected(): boolean {
      if (this.isExporting) {
        return this.exportData.campaignIds.split(',').length > 1;
      }
      return this.$store.state?.filters?.selectedCampaigns?.length > 1;
    },
    cardHeight(): number {
      let height = 1100;
      if (this.isExporting) {
        height = 1060;
      } else if (this.multipleCampsSelected) {
        height = this.componentHeight;
      }
      return height;
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rendered(ctx: any): void {
      this.childHasRendered = true;
      if (this.isXLS) {
        this.$emit('rendered', ctx);
      }
    },
  },
});
