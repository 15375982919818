
import Vue from 'vue';
import NoChartData from '../no-data/NoChartData.vue';
import utils from '../../../../util';
import { format, parse } from 'date-fns';
import { EChartsOption } from 'echarts/types/dist/shared';
import Tooltips from '../tooltip/chartTooltip.vue';
import ExportDownloadBtn from '../buttons/exportDownloadBtn.vue';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import { C360Icon } from '@c360/ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ECharts = (window as any).echarts || undefined;
if (ECharts === undefined) {
  // eslint-disable-next-line no-console
  console.error('ECharts is not defined');
}

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'lineBarChart',
  components: { NoChartData, Tooltips, ExportDownloadBtn, EditModuleBtn, C360Icon },
  props: [
    'sectionConfig',
    'componentConfig',
    'title',
    'subTitle',
    'icon',
    'chartType',
    'commonTweaks',
    'dataSource',
    'dataKey',
    'theme',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'componentHeight',
  ],
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
    ctrUsesPercent: boolean;
    width: string;
    height: string;
    widthOffset: number;
    backgroundBarActive: number;
    toolTipData: object | null;
    toolTipPosition: object;
    toolTipContentPosition: object;
    toolTipHovered: boolean;
    toolTipShowTimer: number;
    toolTipHideTimer: number;
    selectedMarker: string | null;
    dropdownMenuOpen: boolean;
    canvasWidth: string;
    canvasHeight: string;
  } => ({
    chartInstance: undefined,
    ctrUsesPercent: false,
    width: '100%',
    height: '500px',
    widthOffset: 0,
    backgroundBarActive: -1,
    toolTipData: null,
    toolTipPosition: { opacity: 0 },
    toolTipContentPosition: {},
    toolTipHovered: false,
    toolTipShowTimer: 0,
    toolTipHideTimer: 0,
    selectedMarker: null,
    dropdownMenuOpen: false,
    canvasWidth: 'auto',
    canvasHeight: '450px',
  }),
  watch: {
    chartData: 'initChart',
    '$store.state.customer.theme': 'initChart',
  },
  created() {
    this.selectedMarker = this.componentConfig.lineSeriesKeys[0];
  },
  mounted() {
    window.addEventListener('optimizedResize', this.onWindowResize);
    this.initChart();
    unwatchDataChanges = utils.fireOnAdDataChange(this, this.initChart, true);
  },
  beforeDestroy() {
    unwatchDataChanges();
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
  computed: {
    loading(): boolean {
      return utils.isWaitingOnData(this);
    },
    canExportToXLS(): boolean {
      if (this.$store.state.customer.currentSection?.xlsExportLocalOnly) {
        if (!utils.isLocalDev()) {
          return false;
        }
      }
      if (this.$store.state.customer.currentSection?.xlsExportDevOnly) {
        if (!utils.isLocalDev() && !utils.isDevelopment()) {
          return false;
        }
      }
      return this.hasEnoughData && !!this.componentConfig?.exportableTableData;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    tweaks(): string[] {
      if (!this.componentConfig.commonTweaks) {
        return [];
      }
      if (Array.isArray(this.componentConfig.commonTweaks)) {
        return this.componentConfig.commonTweaks;
      }
      return [this.componentConfig.commonTweaks];
    },
    chartClass(): string {
      let css = 'line-bar-chart';
      if (this.componentConfig.class) {
        css = css + ' ' + this.componentConfig.class;
      }
      if (this.isExporting) {
        css = css + ' exporting';
      }
      return css;
    },
    colorset(): string[] {
      const colors: string[] = [];
      const greyColors = ['#c6c6c6', '#5f5e5e', '#a6a6a6', '#000000', '#ff0000'];
      let greyColorIndex = 0;
      // const combinedKeys = this.barSeriesKeys.concat(this.lineSeriesKeys);
      const colorIndex = this.combinedKeys.indexOf(this.selectedMarker);
      let lineColor = this.Theme.config.chartColors[colorIndex] || '#1e5380';

      if (this.componentConfig.chartColorIndex) {
        // a way to override color
        lineColor = this.Theme.config.chartColors[this.componentConfig.chartColorIndex];
      }

      // need to use original order for some reason (?)
      this.combinedKeys.forEach((key: string) => {
        if (key !== this.selectedMarker) {
          const grey = greyColors[greyColorIndex];
          colors.push(grey);
          greyColorIndex += 1;
        } else {
          colors.push(lineColor);
        }
      });

      return colors;
    },
    chartConfig(): EChartsOption {
      let lineColor, textColor;
      if (this.Theme.config.lineCharts?.style === 'minimal') {
        lineColor = '#fff';
        textColor = '#000';
      } else {
        lineColor = '#000';
        textColor = lineColor;
      }
      if (this.isExporting) {
        lineColor = '#333';
      }

      let colorset = this.colorset;
      if (this.componentConfig.useChartColorIndex > -1) {
        colorset = [this.colorset[this.componentConfig.useChartColorIndex]];
      }

      const twoBottomBarsTweak = !!this.componentConfig.twoBottomBarsTweak;

      const styleConfig = {
        c360: {
          legend: {
            bottom: 0,
            left: 10,
            textStyle: {
              fontWeight: 600,
            },
          },
          grid: {
            top: 20,
            left: 0,
            width: '99%',
            containLabel: true,
          },
          series: {
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 2,
            },
            backgroundStyle: {
              color: 'rgba(238, 238, 238, 1)',
            },
          },
        },
      };
      const currentStyle = styleConfig.c360;

      const cfg: EChartsOption = {
        grid: this.isExporting
          ? {
              containLabel: true,
              left: this.componentConfig.canvasPosition?.left || 5,
              top: this.componentConfig.canvasPosition?.top || 10,
              right: this.componentConfig.canvasPosition?.right || 10,
              bottom: 30,
              height: this.componentConfig.canvasPosition?.height,
              width: this.componentConfig.canvasPosition?.width,
            }
          : currentStyle.grid,
        animation: !this.isExporting,
        xAxis: {
          type: 'category',
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: '#000',
          },
          splitLine: {
            show: false,
          },
          data: this.chartData[0],
        },
        tooltip: {
          trigger: 'axis',
          show: true,
          backgroundColor: 'rgb(0, 0, 0, 0.5)',
          borderWidth: 0,
          textStyle: {
            color: 'rgb(255, 255, 255, 1)',
          },
        },
        color: colorset,
        width: '99%',
        // color: this.Theme.config.chartColors,
        // color: ['#00bfa5', '#ff9100'],
      };
      const yAxisLines = this.lineSeriesKeys.reduce((lines: object[], key: string) => {
        lines.push({
          show: key === this.selectedMarker && !this.isExporting,
          type: 'value',
          splitLine: {
            show: true,
          },
          scale: this.isExporting,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          position: 'left',
          axisLabel: {
            color: textColor,
            padding: [0, 0, 0, 0],
          },
          boundaryGap: this.isExporting ? [1, 0] : [0, 0],
        });
        return lines;
      }, []);
      const yAxisBars = this.barSeriesKeys.reduce((bars: object[], key: string, index: number) => {
        bars.push({
          type: 'value',
          show: key === this.selectedMarker,
          axisLabel: {
            formatter: function (value) {
              return key === 'ResponseRate' ? `${value.toFixed(2)}%` : value;
            },
          },
          position: 'left',
          color: [index + 1],
          data: this.chartData[index + 1 + this.lineSeriesKeys.length],
          boundaryGap: this.isExporting ? [0, 5] : [0, 0],
        });
        return bars;
      }, []);

      const yAxisBarsAndLines = yAxisLines.concat(yAxisBars);
      cfg.yAxis = yAxisBarsAndLines;

      const lineSeries = this.lineSeriesKeys.reduce((lines: object[], key: string, index: number) => {
        lines.push({
          ...this.lineConfig,
          type: 'line',
          // selectMode: false,
          animation: !this.isExporting,
          name: this.getLegendKeyName(key),
          yAxisIndex: index,
          data: this.chartData[index + 1],
          smooth: true,
          symbol: 'emptyCircle',
          symbolSize: this.isExporting ? 12 : 0,
          itemStyle: {
            borderWidth: this.isExporting ? 12 : 0,
            borderColor: '#FFF',
          },
          label: {
            show: this.isExporting,
            position: 'top',
            padding: 5,
            color: '#000',
            formatter: params => {
              try {
                if (!this?.chartData?.length) {
                  return null;
                }
                const valArray = this.chartData[1];
                let val;
                if (Array.isArray(valArray)) {
                  val = utils.formatNumberWithCommas(valArray[params.dataIndex]);
                }
                return val;
              } catch (exp) {
                // eslint-disable-next-line no-console
                console.error('line label formatter', exp, params);
              }
              return null;
            },
          },
          zlevel: key === this.selectedMarker ? 3 : 1,
        });
        return lines;
      }, []);

      if (Array.isArray(this.chartData) && this.chartData.length) {
        const barSeries = this.barSeriesKeys.reduce((bars: object[], key: string, index: number) => {
          const barCfg = {
            type: 'bar',
            // selectMode: false,
            animation: true,
            name: this.getLegendKeyName(key),
            yAxisIndex: index + this.lineSeriesKeys.length,
            data: this.chartData[index + 1 + this.lineSeriesKeys.length],
            barWidth: this.chartData[0].length > 7 ? 10 : 25,
            barGap: '20%',
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
                ...currentStyle.series.itemStyle,
              },
            },
            showBackground: true,
            backgroundStyle: currentStyle.series.backgroundStyle,
            show: true,
            label: {
              show: false,
              position: 'top',
              padding: twoBottomBarsTweak ? [-5, 0, 0, 0] : 5,
              margin: 0,
              color: '#000',
              formatter: params => {
                try {
                  if (params?.seriesName === 'Response Rate') {
                    if (params?.data) {
                      return `${params.data.toFixed(2)}%`;
                    }
                  }
                  if (!this?.chartData?.length) {
                    return null;
                  }
                  let val = this.chartData[this.lineSeriesKeys.length + params.seriesIndex][params.dataIndex]; // first serie is x labels, then skip the line series
                  val = utils.formatNumberWithCommas(val);
                  if (this.isExporting && val === '0') {
                    return '';
                  }
                  // if (params.seriesIndex === 2) {
                  //   return '   ' + val + '%';
                  // }
                  return val;
                } catch (exp) {
                  // eslint-disable-next-line no-console
                  console.error('bar label formatter', exp, params);
                }
                return null;
              },
            },
          };
          if (this.isExporting) {
            barCfg.animation = false;
            barCfg.label.show = true;
            if (typeof this.componentConfig.barLabel === 'object') {
              barCfg.label = {
                ...barCfg.label,
                ...this.componentConfig.barLabel,
              };
            }
            barCfg.labelLayout = this.componentConfig.barLabelLayout;
          }
          bars.push(barCfg);
          return bars;
        }, []);

        // legend keys
        const legendKeys: string[] = [];
        this.componentConfig.lineSeriesKeys.forEach((key: string) => {
          legendKeys.push(this.getLegendKeyName(key));
        });
        this.componentConfig.barSeriesKeys.forEach((key: string) => {
          legendKeys.push(this.getLegendKeyName(key));
        });
        cfg.legend = {
          show: this.componentConfig.hideLegend !== true,
          data: legendKeys,
          selectedMode: false,
          ...currentStyle.legend,
          icon: 'circle',
        };

        if (this.isExporting) {
          if (typeof this.componentConfig?.legend?.orient !== 'undefined') {
            cfg.legend.orient = this.componentConfig.legend.orient;
          }
          if (typeof this.componentConfig?.legend?.align !== 'undefined') {
            cfg.legend.align = this.componentConfig.legend.align;
          }
          if (typeof this.componentConfig?.legend?.left !== 'undefined') {
            cfg.legend.left = this.componentConfig.legend.left;
          }
          if (typeof this.componentConfig?.legend?.top !== 'undefined') {
            cfg.legend.top = this.componentConfig.legend.top;
          }
        }

        cfg.series = lineSeries.concat(barSeries);
        if (this.tweaks.includes('percentageData')) {
          cfg.tooltip = {
            trigger: 'axis',
            formatter: params => {
              try {
                const lines = params.filter(param => param.seriesType === 'line');
                const bars = params.filter(param => param.seriesType === 'bar');
                const formattedLinesString =
                  `${lines[0].name}<br/>` +
                  lines.map(line => `${line.marker} ${line.seriesName}: ${line.data}`).join('<br/>');
                const formattedBarsString = bars
                  .map(bar => {
                    let data = bar.data;
                    if (bar.seriesName !== 'Impressions') {
                      if (
                        [
                          'ottDailyPerformanceLineAndBarChart',
                          'tv2ottPerformanceByDayOfWeekLineChart',
                          'tv2ottResponsesByWeekLineAndBarChart',
                        ].includes(this.componentConfig.cid) &&
                        bar.seriesName === 'Response Rate'
                      ) {
                        data = `${data.toFixed(2)}%`;
                      } else {
                        data = utils.formatFloatToPercent(bar.data);
                      }
                    }
                    return `${bar.marker} ${bar.seriesName}: ${data}`;
                  })
                  .join('<br/>');
                return `${formattedLinesString}<br/>${formattedBarsString}`;
              } catch (exp) {
                // eslint-disable-next-line no-console
                console.error('tooltip formatter', exp, params);
              }
              return null;
            },
          };
        }
      }
      return cfg;
    },
    lineSeriesKeys(): string[] {
      let lineSeriesKeys = this.componentConfig.lineSeriesKeys;
      if (!Array.isArray(lineSeriesKeys)) {
        if (typeof lineSeriesKeys === 'string') {
          lineSeriesKeys = [lineSeriesKeys];
        } else {
          lineSeriesKeys = [];
        }
      }
      const hideEmptySeriesKeys = this.componentConfig.hideEmptySeriesKeys;
      if (hideEmptySeriesKeys) {
        const rows = utils.adDataForKey(this, this.dataSource);
        // remove keys that don't have any data
        hideEmptySeriesKeys.forEach((k: string) => {
          if (!rows.find(x => !!x[k])) {
            lineSeriesKeys = lineSeriesKeys.filter(x => x !== k);
          }
        });
      }

      // order to make selected line last so non-selected dont overlap
      // if (lineSeriesKeys.length > 1 && this.selectedMarker) {
      //   const keys: string[] = [this.selectedMarker];
      //   lineSeriesKeys.forEach((key: string) => {
      //     if (key !== this.selectedMarker) keys.push(key);
      //   });
      //   lineSeriesKeys = keys;
      // }
      return lineSeriesKeys;
    },
    barSeriesKeys(): string[] {
      let barSeriesKeys = this.componentConfig.barSeriesKeys;
      if (!Array.isArray(barSeriesKeys)) {
        if (typeof barSeriesKeys === 'string') {
          barSeriesKeys = [barSeriesKeys];
        } else {
          barSeriesKeys = [];
        }
      }
      const hideEmptySeriesKeys = this.componentConfig.hideEmptySeriesKeys;
      if (hideEmptySeriesKeys) {
        const rows = utils.adDataForKey(this, this.dataSource);
        // remove keys that don't have any data
        hideEmptySeriesKeys.forEach((k: string) => {
          if (!rows.find(x => !!x[k])) {
            barSeriesKeys = barSeriesKeys.filter(x => x !== k);
          }
        });
      }

      // order to make selected line last so non-selected dont overlap
      // if (barSeriesKeys.length > 1 && this.selectedMarker) {
      //   const keys: string[] = [this.selectedMarker];
      //   barSeriesKeys.forEach((key: string) => {
      //     if (key !== this.selectedMarker) keys.push(key);
      //   });
      //   barSeriesKeys = keys;
      // }
      return barSeriesKeys;
    },
    combinedKeys(): string[] {
      return this.lineSeriesKeys.concat(this.barSeriesKeys);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartData(): any {
      let rows = utils.adDataForKey(this, this.dataSource);
      if (!rows || !Array.isArray(rows)) {
        return [];
      }
      // check for hours for sorting.
      const isHours = this.tweaks?.includes('hourbackfill');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rows = rows.map((x: any) => ({ ...x })); // shallow clone, for transforms to stay localized to this copy of the data
      const sortedRows = rows.sort(function (a, b) {
        const dateA = new Date(a.Date).getTime();
        const dateB = new Date(b.Date).getTime();
        if (isHours) return dateA > dateB ? -1 : 1;
        return dateA > dateB ? 1 : -1; // ? -1 : 1 for ascending/increasing order
      });
      if (!this.tweaks.includes('dontFillZeros')) {
        // we will fill in missing dates with empty data;
        const firstDate = sortedRows[0].Date;
        const lastDate = sortedRows[sortedRows.length - 1].Date;
        // 86400000 ms in a day
        if (lastDate && firstDate) {
          const dates = [...Array(Date.parse(lastDate) / 86400000 - Date.parse(firstDate) / 86400000 + 1).keys()].map(
            k => new Date(86400000 * k + Date.parse(firstDate)).toISOString().slice(0, 10),
          );
          // console.log(sortedRows);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result: any[] = []; // empty data array
          for (let i = 0, j = 0; i < dates.length; i++) {
            // if the date in the data matches the date in the empty data array, copy the values and increment j
            // otherwise use empty values and do not increment j
            result[i] = { Date: dates[i] };
            if (dates[i] === sortedRows[j].Date) {
              this.componentConfig.lineSeriesKeys.forEach((key: string) => {
                result[i][key] = sortedRows[j][key];
              });
              j += 1;
            } else {
              this.componentConfig.lineSeriesKeys.forEach((key: string) => {
                const zeroValue = this.usesPercent(key) ? '0.0%' : 0;
                result[i][key] = zeroValue;
              });
            }
          }
          rows = result;
        }
      } // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any[] = [];

      // console.log({ lineSeriesKeys, barSeriesKeys, config: this.componentConfig });

      if (this.tweaks.includes('daybackfill')) {
        // xAxis
        data.push(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);

        // yAxis
        this.lineSeriesKeys.forEach(() => {
          data.push([]); // push an empty array for each key (aka each line series)
        });
        this.barSeriesKeys.forEach(() => {
          data.push([]); // push an empty array for each key (aka each line series)
        });

        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].forEach((d: string) => {
          const day = rows.find((x: { Day: string }) => x.Day === d);
          let rowIndex = 1;

          this.lineSeriesKeys.forEach((key: string) => {
            if (day) {
              if (key === 'ClickThrough') {
                this.ctrUsesPercent = day[key].split('').includes('%');
                data[rowIndex].push(parseFloat(day[key].slice(0, day[key].length - 1)));
              } else {
                data[rowIndex].push(day[key]);
              }
            } else {
              data[rowIndex].push(0);
            }
            rowIndex += 1;
          });
          this.barSeriesKeys.forEach((key: string) => {
            if (day) {
              if (key === 'ClickThrough') {
                this.ctrUsesPercent = day[key].split('').includes('%');
                data[rowIndex].push(parseFloat(day[key].slice(0, day[key].length - 1)));
              } else {
                data[rowIndex].push(day[key]);
              }
            } else {
              data[rowIndex].push(0);
            }
            rowIndex += 1;
          });
        });
      } else if (this.tweaks.includes('datebackfill')) {
        rows = utils.sortByProperty(rows, 'Date');
        // const combinedKeys = this.barSeriesKeys.concat(this.lineSeriesKeys);
        data.push(
          ...rows.reduce(
            (allRows, row) => {
              // set the date to the bottom (x) axis
              // input format is: 2020-02-01
              try {
                const formattedDate = format(
                  parse(row.Date.replace(/\//g, '-'), 'yyyy-MM-dd', new Date()),
                  'MMM dd yy',
                );
                allRows[0].push(formattedDate);
              } catch (exp) {
                // eslint-disable-next-line no-console
                console.log('bad date', exp, row.Date);
              }

              // set all other properties to incrementing index
              this.combinedKeys.forEach((key: string, index: number) => {
                if (key === 'ClickThrough') {
                  this.ctrUsesPercent = row[key].split('').includes('%');
                  allRows[index + 1].push(row[key].slice(0, row[key].length - 1));
                } else if (key === 'CompletionRate') {
                  allRows[index + 1].push(row[key].slice(0, row[key].length - 1));
                } else {
                  allRows[index + 1].push(row[key]);
                }
              });

              return allRows;
            },
            [
              // empty array for Date
              [],
              // get empty arrays for all properties to display on chart
              ...this.combinedKeys.reduce(all => {
                all.push([]);
                return all;
              }, []),
            ],
          ),
        );
      } else if (this.tweaks.includes('daypartbackfill')) {
        // todo
      } else if (this.tweaks.includes('hourbackfill')) {
        // todo
      }

      return data;
    },
    canvasStyle(): object {
      return {
        width: this.canvasWidth,
        height: this.canvasHeight,
      };
    },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) return true;
      return this.chartData?.length > 0;
    },
    showNoDataChart(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      } else if (this.componentConfig.hideIfNoData && !this.hasEnoughData) {
        return false;
      }
      return true;
    },
    limitChartsEnoughData(): boolean {
      return this.chartData.length === 1;
    },
    chartTitle(): string {
      return this.title;
    },
    backgroundBarCount(): number {
      if (this.chartData.length > 0 && Array.isArray(this.chartData[0])) {
        return this.chartData[0].length;
      }
      return 0;
    },
    isMultiMarker(): boolean {
      return this.combinedKeys.length > 1;
    },
  },
  methods: {
    initChart(): void {
      if (this.componentConfig.canvasWidth) {
        this.canvasWidth = this.componentConfig.canvasWidth;
      }
      if (this.componentConfig.canvasHeight) {
        this.canvasHeight = this.componentConfig.canvasHeight;
      }
      setTimeout(() => {
        try {
          if (!this.hasEnoughData) {
            this.$emit('rendered', { empty: true });
            return;
          }
          if (!this.$refs.canvas) {
            this.$emit('rendered', { empty: true });
            return;
          }
          if (
            this.chartData?.length === 0 &&
            this.componentConfig.hideIfNoData &&
            !this.$store.state.layoutEditor.editMode
          ) {
            this.$emit('set-display', false);
          } else {
            this.$emit('set-display', true);
          }
          this.chartInstance = ECharts.init(this.$refs.canvas, null, { renderer: 'svg' });
          if (this.chartInstance) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const c = this.chartInstance as any;
            if (this.isXLS) {
              this.getTableData();
            } else {
              c.on('finished', () => {
                this.$emit('rendered', { empty: false });
              });
              c.on('rendered', () => {
                this.$emit('rendered', { empty: false });
              });
              c.setOption(this.chartConfig);
            }
          } else {
            // eslint-disable-next-line no-console
            console.error('initChart', 'failed to create instance');
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('initChart', error);
        }
      }, 10);
    },
    getTableData(): void {
      let headers: string[] = [];
      if (this.componentConfig.exportTweaks?.addDayOfTheWeekColumn) {
        headers.push('Day');
      } else {
        headers.push('n/a'); // can we know?
      }
      headers = [...headers, ...this.componentConfig.lineSeriesKeys, ...this.componentConfig.barSeriesKeys];

      // loop over chartData[0], those are the x axis values
      const data = [];
      if (Array.isArray(this.chartData) && Array.isArray(this.chartData[0])) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.chartData[0].forEach((key: any, index: number) => {
          const row = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.chartData.forEach((serie: any[]) => {
            const val = serie[index];
            if (typeof val === 'string') {
              row.push(val.trim());
            } else {
              row.push(val);
            }
          });
          data.push(row);
        });
      }
      // console.log('line chart export', headers, data);
      if (data.length === 0) {
        this.$emit('rendered', { empty: true });
      } else {
        this.$emit('rendered', { empty: false, headers, data, config: this.componentConfig });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWindowResize(): any {
      setTimeout(() => {
        if (this.chartInstance) {
          // console.log('chartInstance line resize', this.chartInstance.id);
          this.chartInstance.resize();
        }
      }, 250);
    },
    getLegendKeyName(key: string): string {
      let name;
      if (
        this.sectionConfig.id === 'gtdisplay' ||
        this.sectionConfig.id === 'gtvideo' ||
        (this.isExporting && (this.exportData?.tab === 'gtdisplay' || this.exportData?.tab === 'gtvideo'))
      ) {
        if (key === 'Impressions') {
          return 'Geofencing Impressions';
        } else if (key === 'Imps') {
          return 'Geofencing Imps';
        }
      }
      switch (key) {
        case 'Impressions':
          name = key;
          break;
        case 'Hours':
          name = 'Viewing Hours';
          break;
        case 'ClickThrough':
          name = `CTR`;
          break;
        case 'CompletionRate':
          name = `Completion Rate %`;
          break;
        case 'GeoRetargetingImpressions':
          name = `Retargeting imps`;
          break;
        case 'GeoBehaviorImpressions':
          name = `Behavioral Imps`;
          break;
        case 'GeoCookieImpressions':
          name = `Historical Imps`;
          break;
        case 'GeoVisits':
          name = `Store Visits`;
          break;
        default:
          name = utils.headerNamesMap(key);
          break;
      }
      return name;
    },
    selectMarker(value: string): void {
      if (value !== this.selectedMarker) {
        this.selectedMarker = value;
        this.initChart();
      }
    },
    usesPercent(key: string): boolean {
      return (
        key === 'ClickThrough' ||
        key === 'CompletionRate' ||
        key === 'CVR' ||
        key === 'VCR' ||
        key === 'LinkClickThrough'
      );
    },
  },
});
