
import Vue from 'vue';
import NoChartData from '../no-data/NoChartData.vue';
import utils from '../../../../util';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import Tooltips from '../tooltip/chartTooltip.vue';
import { format, parse } from 'date-fns';

export default Vue.extend({
  inheritAttrs: false,
  name: 'tableList',
  components: { NoChartData, EditModuleBtn, Tooltips },
  props: [
    'sectionConfig',
    'componentConfig',
    'title',
    'chartType',
    'dataSource',
    'isExporting',
    'exportData',
    'componentHeight',
  ],
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
  } => ({
    chartInstance: undefined,
  }),
  watch: {
    breakpoints: {
      handler(): void {
        this.onWindowResize();
      },
    },
  },
  mounted() {
    window.addEventListener('optimizedResize', this.onWindowResize);
    setTimeout(() => {
      if (this.validFeedSource && this.hasEnoughData) {
        this.$emit('rendered', { empty: false });
      } else {
        this.$emit('rendered', { empty: true });
      }
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
  computed: {
    headers(): any {
      return [
        {
          text: 'Pacing',
          value: 'Pacing',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Campaign Name',
          value: 'FriendlyName',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Start Date',
          value: 'StartDate',
          align: 'left',
          sortable: false,
        },
        {
          text: 'End Date',
          value: 'EndDate',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Conv All Time',
          value: 'Conversions',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Imps All Time',
          value: 'Impressions',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Imps Goal',
          value: 'ImpressionsGoal',
          align: 'left',
          sortable: false,
        },
        {
          text: '% of Goal',
          value: 'PercentOfGoal',
          align: 'left',
          sortable: false,
        },
      ];
    },
    showNoDataChart(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      } else if (this.componentConfig.hideIfNoData && !this.hasEnoughData) {
        return false;
      }
      return true;
    },
    loading(): boolean {
      return utils.isWaitingOnData(this);
    },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      }
      return this.chartData[0];
    },
    limitChartsEnoughData(): boolean {
      const data = utils.adDataForKey(this, this.dataSource);
      if (!Array.isArray(data)) {
        return false;
      }
      return data.length === 1;
    },
    chartClass(): string {
      let css = '';
      if (this.componentConfig.class) {
        css = css + ' ' + this.componentConfig.class;
      }
      if (this.isExporting) {
        css = css + ' exporting';
      }
      return css;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartData(): any {
      const rows = utils.adDataForKey(this, this.dataSource);
      if (!rows || !Array.isArray(rows) || rows.length === 0) {
        return [];
      }
      return rows;
    },
    breakpoints(): boolean {
      return this.componentConfig.breakpoints;
    },
    validFeedSource(): boolean {
      return true;
      if (this.componentConfig?.feedSource?.includes(this.chartData[0]?.FeedSource)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWindowResize(): any {
      setTimeout(() => {
        if (this.chartInstance) {
          // console.log('chartInstance line resize', this.chartInstance.id);
          this.chartInstance.resize();
        }
      }, 250);
    },
    getTooltip(metric: string): Array<Tooltip> {
      return [utils.getTooltipsFromMetric(metric)];
    },
    getPacingStyle(value: string): string {
      if (value === '-') return 'pacing-na';
      if (!value) return 'pacing-none';
      const parsedValue = parseFloat(value);
      const [normalPacingBorder, goodPacingBorder, badPacingBorder] = [-5.0, 0.0, 5.0];
      if (parsedValue >= goodPacingBorder && parsedValue < badPacingBorder) {
        return 'pacing-good';
      } else if (parsedValue >= normalPacingBorder && parsedValue < goodPacingBorder) {
        return 'pacing-normal';
      } else return 'pacing-bad';
    },
    formatDate(date: string, type: string): string {
      try {
        if (!date) return type === 'end' ? 'No End Date' : 'No Start Date';
        // const parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss a', new Date()); // parse custom date format
        return format(date, 'MMM d, yyyy');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('campaign date', date, err.message || err);
      }
      return '';
    },
    formatNumber(value): string {
      if (value == null) {
        return '-';
      }
      return utils.formatNumberWithCommas(value);
    },
  },
});
