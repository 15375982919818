
import Vue from 'vue';
import OracleMoat from './_oracleMoat.vue';
import { C360Icon } from '@c360/ui';
import utils from '../../../../../util';
import { format } from 'date-fns';

export default Vue.extend({
  name: 'FooterData',
  inheritAttrs: false,
  components: { OracleMoat, C360Icon },
  props: [
    'componentConfig',
    'sectionConfig',
    'getCampaignDates',
    'selectedCampaigns',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'tacticNames',
    'analyticsType',
    'combinedMetrics',
  ],
  computed: {
    isMinimizied(): boolean {
      return this.combinedMetrics?.length > 12;
    },
    showCampaignType(): boolean {
      if (!this.tacticNames.toLowerCase().includes('youtube')) return false;
      if (!this.$attrs.campaignDetails?.AdFormats?.length) return false;
      return true;
    },
    campaignType(): string {
      return this.$attrs.campaignDetails?.AdFormats.join(', ');
    },
    campaignDates1(): string {
      return this.formatDate(this.getCampaignDates[0]);
    },
    campaignDates2(): string {
      return this.formatDate(this.getCampaignDates[1]);
    },
    hasContractEndDate(): boolean {
      return this.checkContractEndDate(this.getCampaignDates[1]);
    },
    lastModifiedDate(): string {
      return utils.getLastModifiedDate(this).toString();
    },
    showLastUpdated(): boolean {
      if (this.$route.query?.viewCampaigns?.includes(',')) {
        return false;
      } else {
        return true;
      }
    },
    validFeedSource(): boolean {
      if (!this.selectedCampaigns) return false;

      const tacticsForXandr = ['display', 'preroll', 'audio', 'video', 'ott'];

      // create list of valid feed sources when the Oracle Moat logo should be displayed
      const allowedFeedSources = new Set();
      // don't show logo on Display if feedsource is zyp (DASH-1508)
      if (this.analyticsType?.toLowerCase() !== 'display') {
        allowedFeedSources.add('zyp');
      }
      allowedFeedSources.add('beeswax');
      // DASH-4133: always show logo for OTT for demo advertiser; show logo for specified set of tactics for Xandr
      if (
        this.$store.state.advertiser?.advertiserInfo?.data?.PropertyId === '88888888' &&
        this.analyticsType?.toLowerCase() === 'ott'
      ) {
        allowedFeedSources.add('amazon');
        allowedFeedSources.add('simplifi');
        allowedFeedSources.add('xandr');
      }

      if (tacticsForXandr.includes(this.analyticsType?.toLowerCase())) {
        allowedFeedSources.add('xandr');
      }

      // get list of all feed sources in selected campaign
      const selectedFeedSources = this.selectedCampaigns.reduce((acc, campaign) => {
        if (campaign.FeedSource) {
          if (Array.isArray(campaign.FeedSource)) {
            for (const f of campaign.FeedSource) {
              acc.add(f);
            }
          } else if (typeof campaign.FeedSource === 'string') {
            acc.add(campaign.FeedSource?.toLowerCase());
          } else {
            // eslint-disable-next-line no-console
            console.log('unexpected validFeedSource', campaign.FeedSource, typeof campaign.FeedSource);
          }
        }
        return acc;
      }, new Set());

      // check if any of the selected campaigns has a valid feed source
      for (const feedSource of selectedFeedSources) {
        if (allowedFeedSources.has(feedSource)) {
          return true;
        }
      }
      return false;
    },
    lastUpdatedToolTip(): string {
      return utils.getTooltipsFromMetric('lastupdated')?.message;
    },
  },
  methods: {
    formatDate(date: string): string {
      try {
        if (date && date.length > 0 && date !== 'undefined' && date !== 'noEndDate') {
          const parsedDate = utils.normalizeDate(date);
          return format(parsedDate, 'MMM dd, yyyy');
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('campaign date', date, err);
      }
      return '';
    },
    checkContractEndDate(date: string): boolean {
      if (date === 'noEndDate') {
        return false;
      }
      return true;
    },
  },
});
