
import Vue from 'vue';
import utils from '../../../../util';
import { EChartsOption } from 'echarts/types/dist/shared';
import { Conversion, Conversions, TotalConversions } from '@point/utility-classes';
import { Resolution } from '../../../../store/modules/advertiser/types';
import NoChartData from '../no-data/NoChartData.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ECharts = (window as any).echarts || undefined;
if (ECharts === undefined) {
  // eslint-disable-next-line no-console
  console.error('ECharts is not defined');
}

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'genericLineBarChartNew',
  components: { NoChartData },
  props: ['componentConfig', 'title', 'dataSource', 'theme', 'componentHeight', 'breakpoints'],
  data: (): {
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
    canvasWidth: string;
    canvasHeight: string;
    periods: { title: string; value: Resolution }[];
    total: TotalConversions | null;
    conversions: Conversion[] | [];
    selectedResolution: Resolution;
  } => ({
    chartInstance: undefined,
    canvasWidth: 'auto',
    canvasHeight: '450px',
    periods: [
      { title: 'daily', value: Resolution.day },
      { title: 'weekly', value: Resolution.week },
      { title: 'monthly', value: Resolution.month },
    ],
    total: null,
    conversions: [],
    selectedResolution: Resolution.day,
  }),
  watch: {
    chartData: {
      immediate: true,
      handler(): void {
        this.initChart();
        if (
          this.chartData?.length === 0 &&
          this.componentConfig.hideIfNoData &&
          !this.$store.state.layoutEditor.editMode
        ) {
          this.$emit('set-display', false);
        } else {
          this.$emit('set-display', true);
        }
      },
    },
    '$store.state.customer.theme': 'initChart',
    chartColors: {
      handler(): void {
        this.initChart();
      },
    },
    breakpoints: {
      handler(): void {
        this.onWindowResize();
      },
    },
  },
  async created() {
    await this.fetchData();
  },
  mounted() {
    window.addEventListener('optimizedResize', this.onWindowResize);
    unwatchDataChanges = utils.fireOnAdDataChange(this, this.initChart, true);
    this.initChart();
  },
  beforeDestroy() {
    unwatchDataChanges();
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
  computed: {
    dashboardId(): number {
      return this.$store.state.customer?.campaignAdPerformance?.CampaignList[0]?.DashboardId;
    },
    hasEnoughData(): boolean {
      return this.chartData[0] && this.chartData[0].length > 0;
    },
    isLoading(): boolean {
      return this.$store.state.advertiser.isConversionsLoading;
    },
    error(): string {
      return this.$store.state.advertiser.conversionsError;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    canvasStyle(): any {
      return {
        width: this.canvasWidth,
        height: this.canvasHeight,
      };
    },
    chartConfig(): EChartsOption {
      const colorSet = ['#CCCCCC', '#A5B3FD', '#FF634E'];

      const options: EChartsOption = {
        color: colorSet,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '3%',
          right: '3%',
        },
        legend: {
          bottom: '5%',
          left: '2%',
          align: 'left',
          padding: [2, 2, 2, 2],
          itemGap: 14,
          textStyle: {
            color: '#3D3D3D',
            fontSize: 12,
            lineHeight: 12.5,
          },
          icon: 'circle',
          data: ['CVR', 'Post-CLick Conversions', 'Post-view Conversions'],
        },
        xAxis: {
          type: 'category',
          data: this.chartData[0] as string[],
          axisLabel: {
            show: true,
            color: '#CCCCCC',
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            interval: this.chartData[0]?.length > 8 ? 5 : 1,
            lineStyle: {
              color: 'rgba(238, 238, 238, 1)',
              type: 'dashed',
            },
          },
        },
        yAxis: [
          {
            show: false,
            type: 'value',
          },
          {
            type: 'value',
            show: false,
          },
          {
            type: 'value',
            show: false,
          },
        ],
        series: [
          {
            name: 'Post-CLick Conversions',
            type: 'bar',
            stack: 'Conversions',
            data: this.chartData[2],
            barWidth: this.chartData[0]?.length > 8 ? 'auto' : '10%',
          },
          {
            name: 'Post-view Conversions',
            type: 'bar',
            stack: 'Conversions',
            data: this.chartData[3],
            barWidth: this.chartData[0]?.length > 8 ? 'auto' : '10%',
          },
          {
            name: 'CVR',
            type: 'line',
            data: this.chartData[1],
            symbolSize: 9,
            symbol: 'circle',
            smooth: true,
            clip: true,
            lineStyle: {
              width: 2.5,
              shadowColor: 'rgba(255, 255, 255, 1)',
              shadowBlur: 2,
              shadowOffsetY: 0,
            },
            itemStyle: {
              color: '#FF634E',
            },
            emphasis: {
              disabled: false,
              itemStyle: {
                color: '#FF634E',
                shadowColor: 'rgba(255, 255, 255, 1)',
                shadowBlur: 2,
              },
            },
            showSymbol: !(this.chartData[0]?.length > 1),
          },
        ],
      };

      return options;
    },
    chartClass(): string {
      let css = 'generic-line-chart';
      if (this.componentConfig.class) {
        css = css + ' ' + this.componentConfig.class;
      }
      return css;
    },

    chartData(): unknown[] {
      if (!this.conversions) {
        return [];
      }
      const dates = [];
      const conversions = [];
      const postClickConversions = [];
      const postViewConversions = [];
      const cvr = [];

      this.conversions.forEach(item => {
        dates.push(item?.date);
        conversions.push(item?.conversions);
        postClickConversions.push(item?.postClickConversions);
        postViewConversions.push(item?.postViewConversions);
        cvr.push(parseFloat(item?.cvr) / 10 || 0);
      });

      return [dates, cvr, postClickConversions, postViewConversions];
    },
  },
  methods: {
    async changeResolution(resolution: Resolution): Promise<void> {
      if (this.isLoading || resolution === this.selectedResolution) return;
      this.selectedResolution = resolution;
      await this.fetchData();
    },
    async fetchData(): Promise<void> {
      const id = this.$route.query?.id || '';
      const daterange = this.$route.query?.daterange || 'alltime';
      const startdate = this.$route.query?.campaignstartdate || '';
      const enddate = this.$route.query?.campaignenddate || '';
      try {
        const data: Conversions = await this.$store.dispatch('advertiser/getConversions', {
          daterange: daterange,
          advertiserId: id,
          campaignDashboardIds: this.dashboardId,
          resolution: this.selectedResolution,
          startdate: startdate,
          enddate: enddate,
        });
        this.total = data?.total;
        this.conversions = data?.data;
      } catch (error) {
        console.error({ error });
      }
    },
    initChart(): void {
      if (this.componentConfig.canvasWidth) {
        this.canvasWidth = this.componentConfig.canvasWidth;
      }
      if (this.componentConfig.canvasHeight) {
        this.canvasHeight = this.componentConfig.canvasHeight;
      }
      try {
        setTimeout(() => {
          if (!this.hasEnoughData) {
            this.$emit('rendered', { empty: true });
            return;
          }
          if (!this.$refs.canvas) {
            this.$emit('rendered', { empty: true });
            return;
          }
          this.chartInstance = ECharts.init(this.$refs.canvas);
          if (this.chartInstance) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const myChart = this.chartInstance as any;
            if (this.isXLS) {
              this.getTableData();
            } else {
              myChart.on('finished', () => {
                this.$emit('rendered', { empty: false });
              });
              myChart.on('rendered', () => {
                this.$emit('rendered', { empty: false });
              });
            }
            myChart.setOption(this.chartConfig, true);
          } else {
            // eslint-disable-next-line no-console
            console.error('initChart', 'failed to create instance');
          }
        }, 10);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('initChart', error);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWindowResize(): any {
      setTimeout(() => {
        if (this.chartInstance) {
          this.chartInstance.resize();
        }
      }, 250);
    },
  },
});
