
import Vue from 'vue';
import analytics from '../../../../mixins/analytics';
import Tooltips from '../tooltip/chartTooltip.vue';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import { C360Icon } from '@c360/ui';
import debounce from 'lodash.debounce';

export default Vue.extend({
  inheritAttrs: false,
  name: 'tableHeaderC360',
  components: { Tooltips, EditModuleBtn, C360Icon },
  mixins: [analytics],
  props: [
    'tableTitle',
    'isExporting',
    'componentConfig',
    'sectionConfig',
    'tacticFilterList',
    'filterByValueColumns',
    'headerName',
    'selectedSortBy',
    'filterByValue',
    'exportPagination',
    'selectedTacticFilter',
    'dataFilledTooltips',
    'isOrder',
    'percentage',
  ],
  data: (): {
    sortByMenuOpen: boolean;
    filterByTactic: boolean;
    searchOpen: boolean;
    search: string;
    sortByOrder: Object;
  } => ({
    sortByMenuOpen: false,
    filterByTactic: false,
    searchOpen: false,
    search: '',
    sortByOrder: [
      {
        name: 'Name',
        key: 'name_asc',
      },
      {
        name: 'Start Date',
        key: 'start_asc',
      },
      {
        name: 'End Date',
        key: 'end_asc',
      },
    ],
  }),
  computed: {
    isOrderDetailsPage(): boolean {
      return this.$route?.query?.tab === 'ordersummary';
    },
    isEdit(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
    selectedRange(): string {
      const orderState = this.$store.state.customer.orderListState;
      return orderState?.selectedRange || 'thismonth';
    },
    showDateRangeLabel(): boolean {
      return this.selectedRange !== 'alltime' && this.isOrderDetailsPage;
    },
    currentOrderSort(): string {
      return this.$store.state.order.orderListParams.sortBy;
    },
    currentOrderSortForDisplay(): string {
      return this.sortByOrder.find(val => val.key === this.currentOrderSort).name;
    },
  },
  methods: {
    sortOrder(sortValue) {
      this.analyticTrack(
        this.trackValue.ORDERS_CHANGE_SORT,
        `Sort by: ${this.sortByOrder.find(val => val.key === sortValue).name}`,
      );

      const orderParams = this.$store.state.order.orderListParams;
      this.$store.dispatch('order/setOrderlistParams', {
        ...orderParams,
        offset: 0,
        sortBy: sortValue,
      });
    },
    clearSearch() {
      this.searchOpen = false;
      this.search = '';
    },
    debouncedSearch: debounce(function (): void {
      const orderParams = this.$store.state.order.orderListParams;
      this.$store.dispatch('order/setOrderlistParams', {
        ...orderParams,
        offset: 0,
        orderName: this.search,
      });
    }, 2000),
  },
  watch: {
    search: {
      handler(): void {
        if (this.isOrder) {
          this.debouncedSearch();
        } else {
          this.$emit('search', this.search);
        }
      },
    },
  },
});
