<template>
  <v-card class="no-map-data ma-0 elevation-0" :height="mapHeight">
    <!-- edit button -->
    <div class="d-flex justify-end edit-module-btn" v-if="$store.state.layoutEditor.editMode">
      <edit-module-btn
        :sectionId="sectionConfig.id"
        :componentId="componentConfig.id"
        :componentTitle="componentConfig.title"
      ></edit-module-btn>
    </div>
    <c360-icon icon="Globe" />
    <h5 class="subheading">
      There is currently
      {{ limitChartsEnoughData ? 'not enough data' : 'no data' }}
      available for this mapi
    </h5>
  </v-card>
</template>

<script>
import util from '../../../../util';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import { C360Icon } from '@c360/ui';

export default {
  inheritAttrs: false,
  props: {
    title: String,
    limitChartsEnoughData: Boolean,
    isExporting: Boolean,
    isExportDynamic: Boolean,
    exportData: Object,
    exportContext: Object,
    mapHeight: Number,
    sectionConfig: Object,
    componentConfig: Object,
  },
  components: { EditModuleBtn, C360Icon },
  computed: {},
};
</script>

<style lang="scss" scoped>
.exporting {
  box-shadow: none !important;
  background: #ffffff;
  height: 100%;
}

.no-map-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
