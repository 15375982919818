/* eslint-disable no-unused-vars */
import { AdvertiserInfo, Contacts, Conversions } from '@point/utility-classes';
export interface StateType {
  advertiserInfo: AdvertiserInfo | null;
  contacts: Contacts | null;
  conversions: Conversions | null;

  advertiserInfoError: string | null;
  contactsError: string | null;
  conversionsError: string | null;
  updateAdvertiserError: string | null;
  updateContactsError: string | null;

  advertiserInfoCache: unknown | null;
  isAdvertiserInfoCached: boolean;

  isAdvertiserInfoLoading: boolean;
  isContactsLoading: boolean;
  isConversionsLoading: boolean;
  isUpdateAdvertiserLoading: boolean;
  isUpdateContactsLoading: boolean;
}

export enum Resolution {
  day = 'day',
  week = 'week',
  month = 'month',
}

export type ConversionsPayload = {
  daterange: string;
  advertiserId: string;
  campaignDashboardIds: string;
  resolution: Resolution;
  startdate?: string;
  enddate?: string;
};
