
import Vue from 'vue';

export default Vue.extend({
  name: 'allCampaignNamesIfNeeded',
  components: {},
  props: ['exportData', 'exportContext'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: (): any => ({}),
  computed: {},
  mounted(): void {
    if (!Array.isArray(this.exportData?.adData?.CampaignList) || this.exportData.adData.CampaignList.length < 2) {
      setTimeout(() => {
        this.$emit('rendered', { empty: true });
      }, 1);
      return;
    }
    setTimeout(() => {
      this.$emit('rendered', { empty: false });
    }, 10);
  },
});
