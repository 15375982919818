
import Vue from 'vue';
import utils from '../../../../util';
import { eventBus } from '../../../../main';
import { C360Icon } from '@c360/ui';
export default Vue.extend({
  name: 'ExportDownloadBtn',
  data: (): {} => ({
    menuOpened: false,
  }),
  components: {
    C360Icon,
  },
  props: {
    canExportToXLS: Boolean,
    canExportToCSV: Boolean,
    id: String,
    title: String,
  },
  methods: {
    exportTableDataXLSX(): void {
      eventBus.$emit('exportComponentXLS', { id: this.id, title: this.title });
    },
    exportTableDataCSV(): void {
      eventBus.$emit('exportComponentCSV', { id: this.id, title: this.title });
    },
    downloadTableData(): void {
      let columns = this.componentConfig?.columns;
      if (!columns) {
        columns = Object.keys(this.tableData[0]);
      }
      let csv = '';
      columns.forEach((header: string) => {
        // csv = csv + '"' + header.text + '",';
        csv = csv + header + '\t';
      });
      csv = csv.substr(0, csv.length - 1) + '\n';

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.tableData.forEach((creative: any) => {
        if (creative.Tactic === 'All Tactics') {
          return;
        }
        columns.forEach((header: string) => {
          // csv = csv + '"' + data[header] + '",';
          const val = creative[header];
          if (val) {
            csv = csv + val + '\t';
          } else {
            csv = csv + '\t';
          }
        });
        // const name = this.getCreativeName(creative.CreativeId);
        // if (name) {
        //   csv = csv + name + '\t';
        // } else {
        //   csv = csv + '\t';
        // }
        csv = csv.substr(0, csv.length - 1) + '\n';
      });
      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', csv]);
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;

      const query = { ...this.$route.query };
      const title = this.componentConfig?.title || 'Table Data';
      const advId = query.id || 'advertiser';
      const campId = query.viewCampaigns || 'campaign';
      const now = new Date().getTime();
      downloadLink.download = `${title} - ${advId} - ${campId} - ${now}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },
  computed: {
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
  },
});
