
import Vue from 'vue';
import ChartTooltip from '../../../charts/tooltip/chartTooltip.vue';
import utils from '../../../../../util';
import SiteTrafficBar from '../../../progressBars/siteTrafficBar.vue';
import CampaignProgressBar from '../../../progressBars/progressBar.vue';
import { Tooltip } from '../../../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
export default Vue.extend({
  name: 'campaignSummaryPage1',
  components: {
    CampaignProgressBar,
    ChartTooltip,
    SiteTrafficBar,
  },
  props: [
    'primaryColor',
    'combinedMetrics',
    'isSponsoredGAM',
    'sponsoredGamProgress',
    'impressionMetricFontValues',
    'hasImpressionsProgressBar',
    'tacticNames',
    'impressionProgressValues',
    'hasReachAndFrequency',
    'showReverseMetricOrder',
    'hasSiteTrafficProgressBar',
    'siteTrafficProgressValues',
    'standAlone',
    'componentConfig',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'dateRangeSelected',
  ],
  computed: {
    hasMoreThanTwelveMetrics(): boolean {
      return this.combinedMetrics.length > 12;
    },
    isBeeswax(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('BEESWAX');
    },
  },
  methods: {
    getTooltips({ key }: { title: string; value: string; key: string }): Array<Tooltip> {
      // special case for FBAds.
      if (
        this.componentConfig.dataSource === 'SOCIAL.GeoPerformanceImpression' &&
        key?.toLowerCase() === 'clickthrough'
      ) {
        key = 'socialctr';
      }
      if (this.componentConfig.dataSource === 'SOCIAL.GeoPerformanceImpression' && key?.toLowerCase() === 'clicks') {
        key = 'socialclicks';
      }
      if (this.componentConfig.dataSource === 'VIDEO' && key?.toLowerCase() === 'clickthrough') key = 'digitalvideoctr';
      if (
        (this.componentConfig.dataSource === 'OTT' || this.componentConfig.dataSource === 'VIDEO') &&
        key?.toLowerCase() === 'reach'
      ) {
        key = 'ottdigitalvideoreach';
      }
      if (
        (this.componentConfig.dataSource === 'OTT' || this.componentConfig.dataSource === 'VIDEO') &&
        key?.toLowerCase() === 'frequency'
      ) {
        key = 'ottdigitalvideofrequency';
      }
      if (this.componentConfig.dataSource === 'OTT' && key?.toLowerCase() === 'completionrate') {
        key = 'ottcompletionrate';
      }
      if (this.componentConfig.dataSource === 'OTT' && key?.toLowerCase() === 'conversions') {
        key = 'conversionsott';
      }
      if (this.componentConfig.dataSource === 'VIDEO' && key?.toLowerCase() === 'completionrate') {
        key = 'digitalvideocompletionrate';
      }
      if (this.componentConfig.dataSource === 'GOOGLEVIDEO.GeoPerformanceImpression') {
        switch (key.toLowerCase()) {
          case 'vc100':
            key = 'googlevideo100%viewed';
            break;
          case 'viewrate':
            key = 'googlevideoviewrate';
            break;
          case 'clickthrough':
            key = 'googlevideoctr';
            break;
          default:
            break;
        }
      }
      return [utils.getTooltipsFromMetric(key)];
    },
    metricName(metric: string): string {
      if (metric === 'Completion Rate') return 'VCR';
      return metric;
    },
    formatValue(metric): string | number {
      if (metric?.value === undefined) return 0;
      if (metric?.value?.toLowerCase() === 'unknown' || metric?.value?.toLowerCase() === 'other') return 0;
      if (metric?.key === 'InnovidXPCPM') return `$${metric?.value}`;
      if (metric?.key === 'InnovidXPCostPerResponse') return `$${parseFloat(metric?.value).toFixed(2)}`;
      return utils.formatNumberWithCommas(metric?.value);
    },
    handleSwitchDateRange(newValue) {
      this.$emit('switchDateRange', newValue);
    },
  },
});
