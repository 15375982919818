
import Vue from 'vue';
import utils from '../../../../../util';
import { Tooltip } from '../../../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
import ChartTooltip from '../../../charts/tooltip/chartTooltip.vue';

export default Vue.extend({
  name: 'WebliftMetrics',
  props: ['metrics'],
  components: { ChartTooltip },
  computed: {},
  methods: {
    getTooltips({ key }: { title: string; value: string; key: string }): Array<Tooltip> {
      return [utils.getTooltipsFromMetric(key)];
    },
  },
});
