
import Vue from 'vue';

// load all possible summary layouts
import CardsLeftMapRight from './layouts/cardsLeftMapRight.vue';
import MapSummaryOverlay from './layouts/mapSummaryOverlay.vue';
import MapLeftListRight from './layouts/mapLeftListRight.vue';
import { validProducts } from './utils';
import { CurrentSection } from '../../../types/layout';

export default Vue.extend({
  name: 'SummaryController',
  inheritAttrs: false,
  data: (): {
    componentName: string | null;
    config: object;
  } => ({
    componentName: null, // get from theme. set a default
    config: {
      legend: null,
      loading: true,
      loadedValidData: true,
      dataCacheKey: null,
      dataSource: null,
      tweaks: {},
      campaignDetails: null,
      mapEnabled: false,
      hoveredLayer: null,
      tooltipTitle: null,
      tooltipContent: null,
      layoutThemes: [],
      showTopAlert: true,
    },
  }),
  // include all summary layouts
  components: { CardsLeftMapRight, MapSummaryOverlay, MapLeftListRight },
  props: [
    'commonTweaks',
    'componentConfig',
    'dataSource',
    'headers',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'sectionConfig',
    'title',
    'topOptions',
  ],
  created() {
    this.componentName = this.Theme?.config?.summary?.layout; // get from theme set a default
    this.readConfig();
  },
  mounted() {
    if (!this.isExporting) {
      this.$store.dispatch('summary/resetTopTactics').then(() => {
        this.getSummaryPerformance(this.$store.state.customer.selection);
      });
    } else if (this.isXLS) {
      // wait for overlay render event
    } else if (!this.hasMapData && this.hasValidSummaryData) {
      setTimeout(() => {
        this.$emit('rendered', { empty: false });
      }, 100);
    } else if (!this.hasValidSummaryData) {
      setTimeout(() => {
        if (!this.hasValidSummaryData) {
          this.$emit('rendered', { empty: true });
        }
      }, 5000);
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getSummaryPerformance(selection?: any): void {
      //DASH-4837: because now we don't need to call getSummaryPerformance from this place, it called in home.vue
      if (!this.isShared) {
        return
      }
      // pass token for shared page
      if (this.isShared && selection) selection.token = this.$store.state.customer?.sharedSelection?.etoken;
      this.$store.dispatch('getSummaryPerformance', selection).catch((error: Error) => {
        this.$store.dispatch('showError', error);
        // eslint-disable-next-line no-console
        console.error(error);
      });
    },
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
    readConfig(): void {
      this.config.layoutThemes = this.componentConfig?.themes;
      this.config.dataSource = 'SummaryAPI';
      const selectionKey =
        `${this.$route.query.view || ''} ${this.$route.query.id || ''} ` +
        `${this.$route.query.daterange || ''} ${this.$route.query.startdate || ''} ` +
        `${this.$route.query.enddate || ''} ${this.$route.query.tab || ''}`;

      this.config.dataCacheKey = `${this.config.dataSource}_${selectionKey}`.trim().replace(/[\W_]+/g, '_');
      this.config.mapEnabled = true;
      if (this.componentConfig?.mapTweaks === 'hideMap') {
        this.config.mapEnabled = false;
      }
      this.config.loading = false;
    },
    rendered(config: object): void {
      this.$emit('rendered', config);
    },
  },
  computed: {
    hasValidSummaryData(): boolean {
      let valid = false;
      if (!this.summaryData) {
        // console.log('no summary data');
        return valid;
      }
      for (const product of validProducts) {
        if (this.summaryData[product]) {
          valid = true;
          break;
        }
      }
      // console.log('hasValidSummaryData', valid, validProducts, this.summaryData);
      return valid;
    },
    summaryData(): object {
      let summaryData = this.$store.state.customer?.summaryPerformance;
      if (!summaryData?.loaded && this.isExporting && this.exportData?.summaryData?.pstate) {
        summaryData = { ...this.exportData.summaryData, loaded: true };
      }
      if (!summaryData?.loaded && this.isShared && this.$store.state.customer?.sharedSelection?.adData) {
        summaryData = this.$store.state.customer.sharedSelection.adData;
      }
      return summaryData;
    },
    hasRequiredData(): boolean {
      if (this.isExporting && this.componentConfig.pretendError) {
        return false;
      }
      return !!(this.config.dataSource && this.config.dataCacheKey);
    },
    isShared(): boolean {
      return this.$store.state.customer?.sharedSelection?.aid;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hasMapData(): any {
      const hasMapData = !!(
        (this.summaryData?.DISPLAY?.DMAList && this.summaryData?.DISPLAY?.DMAList?.length) ||
        (this.summaryData?.DISPLAY?.GeoList && this.summaryData?.DISPLAY?.GeoList?.length) ||
        (this.summaryData?.PREROLL?.DMAList && this.summaryData?.PREROLL?.DMAList?.length) ||
        (this.summaryData?.PREROLL?.GeoList && this.summaryData?.PREROLL?.GeoList?.length) ||
        (this.summaryData?.OTT?.DMAList && this.summaryData?.OTT?.DMAList?.length) ||
        (this.summaryData?.OTT?.GeoList && this.summaryData?.OTT?.GeoList?.length) ||
        (this.summaryData?.VIDEO?.DMAList && this.summaryData?.VIDEO?.DMAList?.length) ||
        (this.summaryData?.VIDEO?.GeoList && this.summaryData?.VIDEO?.GeoList?.length) ||
        (this.summaryData?.LINEAR?.GeoList && this.summaryData?.LINEAR?.GeoList?.length) ||
        (this.summaryData?.GAMVIDEO?.GeoList && this.summaryData?.GAMVIDEO?.GeoList?.length) ||
        (this.summaryData?.BROADCAST?.GeoList && this.summaryData?.BROADCAST?.GeoList?.length) ||
        (this.summaryData?.SEM?.GeoList && this.summaryData?.SEM?.GeoList?.length) ||
        (this.summaryData?.SOCIAL?.GeoList && this.summaryData?.SOCIAL?.GeoList?.length) ||
        (this.summaryData?.GAMDISPLAY?.GeoList && this.summaryData?.GAMDISPLAY?.GeoList?.length) ||
        (this.summaryData?.TRUGEOFENCE?.GeoList && this.summaryData?.TRUGEOFENCE?.GeoList?.length)
      );
      return hasMapData;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
  },
});
