import { render, staticRenderFns } from "./_standAloneSummary.vue?vue&type=template&id=bda7f088"
import script from "./_standAloneSummary.vue?vue&type=script&lang=ts"
export * from "./_standAloneSummary.vue?vue&type=script&lang=ts"
import style0 from "./_standAloneSummary.vue?vue&type=style&index=0&id=bda7f088&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports