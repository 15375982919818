
import Vue from 'vue';
import summaryMap from '../components/maps/summary-map.vue';
import SummaryMetricsController from '../controllers/summaryMetricsController.vue';
import SummaryTacticTablesController from '../controllers/summaryTacticTablesController.vue';
import { getTopTacticData, getTopTactics, renderComponent } from '../utils';
import { TopTacticsGroup, TopTacticsWithMetrics } from '../types';
import { CurrentSection } from '../../../../types/layout';

export default Vue.extend({
  name: 'mapLeftListRight',
  inheritAttrs: false,
  props: [
    'sectionConfig',
    'componentConfig',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'config',
    'mapRenderCacheKey',
    'mapCacheKey',
    'hasRequiredData',
    'mapTilerMapId',
    'hasMapData',
  ],
  data: (): {
    tacticCount: number | null;
  } => ({
    tacticCount: null,
  }),
  mounted(): void {
    // need timeout to allow summary data to be set when advertiser change
    setTimeout(() => {
      this.setTopTacticData();
    }, 100);
  },
  components: { SummaryTacticTablesController, summaryMap, SummaryMetricsController },
  computed: {
    mapHeight(): number {
      return this.$store.state?.summary?.dimensions?.mapHeight || 0;
    },
    topTacticData(): object {
      return this.$store.state?.summary?.topTactics;
    },
    summaryHeight(): number {
      return this.$store.state?.summary?.dimensions?.summaryHeight || 0;
    },
    mapColCount(): number {
      let cols = 8;
      if (this.isExporting && this.tacticCount > 5) {
        cols = 6;
      }
      return cols;
    },
    metricsColCount(): number {
      let cols = 4;
      if (this.hasNoMapData) {
        cols = 12;
      } else if (this.isExporting && this.tacticCount > 5) {
        cols = 6;
      }
      return cols;
    },
    summaryData(): object {
      return this.isExporting ? this.exportData?.summaryData : this.$store.state.customer?.summaryPerformance;
    },
  },
  methods: {
    setMapHeight(): void {
      let height = 550;

      if (this.isExporting) {
        height = this.componentConfig.size.height;
      } else if (this.tacticCount > 5) {
        height = this.summaryHeight;
      }

      this.$store.dispatch('summary/setMapHeight', height);
    },
    setTopTacticData(): void {
      const tactics = getTopTactics().reduce((all: Array<TopTacticsWithMetrics>, tactic: TopTacticsGroup) => {
        const data = getTopTacticData(this.summaryData, tactic);
        if (data) {
          all.push(data);
        }
        return all;
      }, []);
      this.tacticCount = tactics.length;
      this.$store.dispatch('summary/setTopTactics', tactics).then(() => {
        setTimeout(() => {
          this.setMapHeight();
        }, 100);
      });
    },
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
    renderComponent(cpnt: string): boolean {
      return renderComponent(cpnt, this.componentConfig.subComponent, this.isExporting);
    },
    rendered(config: object): void {
      this.$emit('rendered', config);
    },
  },
});
