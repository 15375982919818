
import util from '../../../../util';
import { C360Icon } from '@c360/ui';

export default {
  name: 'ChartTooltip',
  components: { C360Icon },
  props: {
    hideIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltips: {
      type: Array,
      required: true,
    },
    displayType: {
      type: String,
      required: false,
      default: 'inline-block',
    },
    iconSize: {
      type: Number,
      required: false,
      default: 16,
    },
    iconColor: {
      type: String,
      required: false,
      default: 'var(--c360-color-charcoal)',
    },
  },
  computed: {
    cleanTooltips(): Array<{ title: string; message: string }> {
      // strip out any empty tooltips
      // ideally this should never happen, but I saw it causing console errors and added this to prevent it @kenyon 2/9/2022
      return this.tooltips.filter(tooltip => tooltip && tooltip.title && tooltip.message);
    },
    isTooltipHidden(): boolean {
      // DASH-3410: show Performance By Platform tooltip only for ZYP campaigns with OTT/CTV tactic
      if (this?.cleanTooltips[0]?.title && this.cleanTooltips[0].title !== 'Performance By Platform') return false;

      const feedSources = util.feedSources(this);
      const isZypOTTPerformanceModule =
        this.$store.state.customer.currentNavTab === 'ott' && feedSources.includes('ZYP');
      return !isZypOTTPerformanceModule;
    },
    tooltipWidth(): number {
      // DASH-3568: Pacing tooltip should be much wider (750)
      // DASH-3617: Dayparts tooltip on Broadcast should be a bit wider to avoid line break in list
      if (this?.tooltips[0]?.title && this.tooltips[0].title === 'Pacing') return 750;
      if (this?.tooltips?.length && this.$store.state.customer.currentNavTab === 'broadcast') {
        const tooltipHeaders = this.tooltips.map(tooltip => tooltip.title);
        if (tooltipHeaders.includes('Dayparts')) return 550;
      }
      return 500;
    },
  },
};
