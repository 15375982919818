
import Vue from 'vue';
import { C360Icon } from '@c360/ui';
export default Vue.extend({
  inheritAttrs: false,
  name: 'CampaignSummaryNav',
  components: { C360Icon },
  props: ['currPage', 'pageCount'],
  methods: {
    onChangeView(isNext: boolean): void {
      this.$emit('onChangeView', isNext);
    },
  },
});
